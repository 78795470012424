import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import SolidLink from '../solid-link/solid-link';
import { SolidLinkColorSchemas } from '../solid-link/solid-link-enum';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import {
  IMediaNewItemProperties,
  IMediaNewItemStates,
} from './media-new-item-interface';

export default class MediaNewItem extends BaseComponent<
  IMediaNewItemProperties,
  IMediaNewItemStates
> {
  public constructor(props: IMediaNewItemProperties) {
    super(props);
    this.state = {
      imageIsReady: false,
    };
  }

  componentDidMount(): void {
    const img = new Image();
    img.onload = () => this.setState({ imageIsReady: true });
    img.src = this.props.item.featuredImage?.node.sourceUrl;
  }
  public render(): ReactNode {
    const { item } = this.props;
    const { imageIsReady } = this.state;

    return (
      <div className="row c-media-featured card">
        <div className="col-lg-6 c-media-featured__col">
          <div className="media media--transparent media--featured media--hover-effect c-media__img">
            {imageIsReady ? (
              <img
                srcSet={item?.featuredImage?.node.srcSet}
                sizes="(min-width: 1504px) 702px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                src={item?.featuredImage?.node.sourceUrl}
                alt={item?.featuredImage?.node.altText}
                loading="lazy"
                width={item?.featuredImage?.node.mediaDetails.width}
                height={item?.featuredImage?.node.mediaDetails.height}
              />
            ) : (
              <div className="loading-overlay__spinner">
                <svg aria-hidden="true" focusable="false" role="presentation" className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle className="path" fill="none" strokeWidth="6" cx="33" cy="33" r="30"></circle>
                </svg>
              </div>
            )}
            <Link
              className="c-link"
              to={`/media${item?.categories?.nodes[0].uri}${item?.slug}`}
            ></Link>
          </div>
        </div>
        <div className="col-lg-6 c-media-featured__col">
          <div className="c-media-featured__wrapper">
            <div className="c-media-featured__details">
              <SolidLink to={`/media${item?.categories?.nodes[0].uri}`}>
                {item?.categories.nodes[0].name}
              </SolidLink>
              <span>{new Date(item?.date).toLocaleDateString()}</span>
              <span>BY {item?.author.node.name}</span>
            </div>
            <h1 className="c-media-featured__title">
              <Link
                className="c-link"
                to={`/media${item?.categories?.nodes[0].uri}${item?.slug}`}
              >
                {item?.title}
              </Link>
            </h1>
            <div className="s-content">
              <p>
                There’s nothing quite like an off-year election — and thanks to
                the rapid adoption of ranked choice voting across the country,
                there’s really never been anything quite like this year’s
                election.
              </p>
            </div>
            <SolidLink
              to={`/media${item?.categories?.nodes[0].uri}${item?.slug}`}
              colorSchema={SolidLinkColorSchemas.HIGHLIGHTED}
            >
              Read More
              <SvgIcon
                width="13"
                viewBox="0 0 20 14"
                paths={[
                  {
                    command: SvgIconPathCommand.ARROW_RIGHT,
                    fill: '#4A1D96',
                  },
                ]}
              />
            </SolidLink>
          </div>
        </div>
      </div>
    );
  }
}
