import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import BreadCrumb from '../../components/bread-crumb/bread-crumb';
import ContainerBox from '../../components/container-box/container-box';
import JoinUs from '../../components/join-us/join-us';
import MediaItemGrid from '../../components/media-item-grid/media-item-grid';
import MediaNewItem from '../../components/media-new-item/media-new-item';
import MediaPostItemTriple from '../../components/media-post-item-triple/media-post-item-triple';
import MediaPostItem from '../../components/media-post-item/media-post-item';
import WordPressPageLayout from '../../components/wordpress-page-layout/wordpress-page-layout';
import { IMediaPageProperties } from '../../global/wordpress-page/media/media-interface';
import { IWordPressPost } from '../../global/wordpress-page/wordpress-page-interface';
import './media.scss';
export default class Media extends BaseComponent<IMediaPageProperties> {
  private mediaItems: IWordPressPost[];
  private latestMediaItems: IWordPressPost[];
  private firstRowMediaItems: IWordPressPost[];

  private latestMediaItemsCount = 0;
  private firstRowMediaItemsCount = 0;

  private newestMediaItem: IWordPressPost;

  public constructor(props: IMediaPageProperties) {
    super(props);

    const { mediaItems } = props.data;

    this.generatePosts(mediaItems.nodes);
  }

  protected declareTranslateCollection(): string {
    return 'media';
  }

  public render(): ReactNode {
    const { [0]: form } = this.props.data.form.nodes;

    return (
      <WordPressPageLayout title={this.translate('pageTitle')}>
        <ContainerBox className="c-section--pageheader">
          <div className="c-pageheader">
            <BreadCrumb
              crumbItems={[
                {
                  label: 'State of Democracy',
                  location: '/',
                },
                {
                  label: 'Media',
                  location: '/media',
                },
              ]}
            />
            <div className="c-section">
              <h1 className="c-section__title">Media</h1>
            </div>
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--featured u-media-width">
          <MediaNewItem item={this.newestMediaItem} />
          {this.latestMediaItems.length > 0 && (
            <div className="row ">
              {this.latestMediaItems.map((mediaItem, index) => {
                return <MediaPostItem item={mediaItem} key={index} />;
              })}
            </div>
          )}
        </ContainerBox>
        <ContainerBox className="c-section--joinus u-media-width">
          <JoinUs form={form} />
        </ContainerBox>
        <ContainerBox className="c-section--triple u-media-width">
          {this.firstRowMediaItems.length > 0 && (
            <div className="row">
              {this.firstRowMediaItems.map((mediaItem, index) => {
                return <MediaPostItemTriple item={mediaItem} key={index} />;
              })}
            </div>
          )}
        </ContainerBox>
        <ContainerBox className="c-section--allposts u-media-width">
          <MediaItemGrid
            items={this.mediaItems.slice(
              1 + this.latestMediaItemsCount + this.firstRowMediaItemsCount
            )}
            showMoreCount={8}
          />
        </ContainerBox>
      </WordPressPageLayout>
    );
  }

  private generatePosts(mediaItems: IWordPressPost[]): void {
    // this.mediaItems = mediaItems;
    // this.newestMediaItem = mediaItems.splice(0, 1)[0];
    // const latestMediaItemsCount = mediaItems.length > 4 ? 4 : mediaItems.length;
    // this.latestMediaItems = mediaItems.splice(0, latestMediaItemsCount);
    // const firstRowMediaItemsCount =
    //   mediaItems.length > 3 ? 3 : mediaItems.length;
    // this.firstRowMediaItems = mediaItems.splice(0, firstRowMediaItemsCount);

    /* using slice instead of splice to prevent modified media items array 
    when use back button or  click on media page link for multiple times */
    this.mediaItems = mediaItems;
    this.newestMediaItem = mediaItems[0];
    this.latestMediaItemsCount =
      mediaItems.length - 1 > 4 ? 4 : mediaItems.length - 1;
    this.latestMediaItems = mediaItems.slice(1, this.latestMediaItemsCount + 1);
    this.firstRowMediaItemsCount =
      mediaItems.length - (this.latestMediaItemsCount + 1) > 3
        ? 3
        : mediaItems.length - (this.latestMediaItemsCount + 1);
    this.firstRowMediaItems = mediaItems.slice(
      this.latestMediaItemsCount + 1,
      this.firstRowMediaItemsCount + this.latestMediaItemsCount + 1
    );
  }
}

export const query = graphql`
  query MediaItemsQuery {
    form: allGfForm(filter: { slug: { eq: "join-us" } }) {
      nodes {
        apiURL
        formFields {
          isRequired
          label
          type
          placeholder
          id
          cssClass
        }
        title
        button {
          text
        }
      }
    }
    mediaItems: allWpPost(sort: { order: DESC, fields: date }) {
      nodes {
        uri
        title
        slug
        id
        content
        featuredImage {
          node {
            sourceUrl
            altText
            srcSet
            sizes
            mediaDetails {
              height
              width
            }
          }
        }
        author {
          node {
            name
          }
        }
        date
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;
